/* global WorldWind */

// Tampere
//const sector = new WorldWind.Sector(59.950, 62.626, 21.448, 26.180);
// Kotka
const sector = new WorldWind.Sector(59.466667, 61.466667, 25.000000, 29.000000);
// App
//const sector = WorldWind.Sector.FULL_SPHERE;

export default sector;
