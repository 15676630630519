import {EoxLabels, EoxSentinel2Cloudless2018, EoxStreets} from "../layers";
import {
    EcmwfPM10,
    EcmwfPM25,
    EcmwfSurfaceCH4,
    EcmwfSurfaceCO2,
    EcmwfSurfaceNO2,
    EcmwfSurfaceO3,
    EcmwfSurfaceSO2
} from "../layers/ecmwf";
import CopernicusCorineLandCover2018 from "../layers/copernicus/CopernicusCorineLandCover2018";
import {faBullseye} from "@fortawesome/free-solid-svg-icons";

/* global WorldWind */

const config = {
    features: {
        search: false,
        link: 'https://www.zerogravity.fi/'
    },
    view: {
        latitude: 60.466667,
        longitude: 26.941667,
        altitude: 5e4,
        minAltitude: 5e3,
        projection: "3D",
    },
    limits: {
        sector: new WorldWind.Sector(59.966667, 60.966667, 26.000000, 28.000000),
        maxAltitude: 1e5,
        minAltitude: 2e4,
    },
    links: [],
};

export default config;

export const layers = [
    /**
     * Basemap layers
     */
    {layer: new EoxSentinel2Cloudless2018(), options: {category: 'base', enabled: true}},

    /**
     * Overlay layers
     */
    {layer: new EoxLabels(), options: {category: 'overlay', enabled: true, opacity: 0.8}},
    {layer: new EoxStreets(), options: {category: 'overlay', enabled: false, opacity: 0.8}},

    /**
     * Configuration layers
     */
    {layer: 'coordinates', options: {category: 'setting', enabled: true}},
    {layer: 'view-controls', options: {category: 'setting', enabled: true}},

    /**
     * Atmosphere layers
     */
    {layer: new EcmwfSurfaceCH4(), options: {category: 'data', enabled: false, opacity: 0.8}},
    {layer: new EcmwfSurfaceCO2(), options: {category: 'data', enabled: false, opacity: 0.8}},
];
