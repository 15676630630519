/* global WorldWind */

export default class EnhancedWmsLayer extends WorldWind.Camera {
    constructor(wwd, sector, maxAltitude, minAltitude) {
        super(wwd);

        this.sector = sector;
        this.maxAltitude = maxAltitude;
        this.minAltitude = minAltitude || 0;
    }

    setFromLookAt(lookAt) {
        // Clamp the latitude and longitude in the correct range
        lookAt.position.latitude = WorldWind.WWMath.clamp(lookAt.position.latitude, this.sector.minLatitude, this.sector.maxLatitude);
        lookAt.position.longitude = WorldWind.WWMath.clamp(lookAt.position.longitude, this.sector.minLongitude, this.sector.maxLongitude);

        // Clamp range to values greater than 1 in order to prevent degenerating to a first-person lookAt when
        // range is zero.
        lookAt.range = WorldWind.WWMath.clamp(lookAt.range, this.minAltitude, this.maxAltitude);

        super.setFromLookAt(lookAt);
    };
}
